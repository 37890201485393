import React, { useState, useEffect } from 'react';
import { AlertCircle, X } from 'lucide-react';

const API_URL = 'https://api-dot-keboon-ai-439616.as.r.appspot.com/api/';
const ITEMS_PER_PAGE = 10;

const ErrorMessage = ({ message }) => (
  <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4 flex items-center">
    <AlertCircle className="mr-2" />
    <span>{message}</span>
  </div>
);

const LoadingSpinner = ({ message = 'Loading...' }) => (
  <div className="text-center py-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#377E62] mx-auto"></div>
    <p className="mt-2 text-gray-500">{message}</p>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex justify-center items-center gap-2 mt-4">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="px-3 py-1 rounded bg-gray-100 disabled:opacity-50"
    >
      Previous
    </button>
    <span className="px-3 py-1">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="px-3 py-1 rounded bg-gray-100 disabled:opacity-50"
    >
      Next
    </button>
  </div>
);

const PriceAnalysis = ({ onBack }) => {
  const [averagePrices, setAveragePrices] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemLoading, setItemLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [historyPage, setHistoryPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    fetchAveragePrices();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const handleError = (error, context) => {
    console.error(`Error in ${context}:`, error);
    let errorMessage = 'An unexpected error occurred. Please try again later.';
    
    if (error.response) {
      errorMessage = error.response.data?.detail || `Server error: ${error.response.status}`;
    } else if (error.request) {
      errorMessage = 'Unable to reach the server. Please check your connection.';
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    return errorMessage;
  };

  const fetchAveragePrices = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`${API_URL}average-prices`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
      
      const data = await response.json();
      setAveragePrices(data);
    } catch (error) {
      setError(handleError(error, 'fetching prices'));
    } finally {
      setLoading(false);
    }
  };

  const fetchItemPrice = async (itemCode) => {
    try {
      setItemLoading(true);
      setError(null);
      setShowDetail(true);
      
      const response = await fetch(`${API_URL}item-price/${itemCode}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
      
      const data = await response.json();
      setSelectedItem(data);
      setHistoryPage(1);
    } catch (error) {
      setError(handleError(error, 'fetching item details'));
    } finally {
      setItemLoading(false);
    }
  };

  const filteredPrices = Object.entries(averagePrices).filter(([itemName, data]) => 
    itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    data.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    data.group?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    data.unit?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredPrices.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = filteredPrices.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const getPriceHistory = () => {
    if (!selectedItem?.price_history) return [];
    const history = Object.entries(selectedItem.price_history)
      .sort((a, b) => new Date(b[0]) - new Date(a[0]));
    
    const startIdx = (historyPage - 1) * ITEMS_PER_PAGE;
    return history.slice(startIdx, startIdx + ITEMS_PER_PAGE);
  };

  const totalHistoryPages = selectedItem?.price_history 
    ? Math.ceil(Object.keys(selectedItem.price_history).length / ITEMS_PER_PAGE)
    : 0;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <button
          onClick={onBack}
          className="px-4 py-2 text-[#377E62] hover:text-[#52AE77] transition-colors"
        >
          ← Back to Home
        </button>
      </div>

      <div className="grid gap-6 md:grid-cols-2 relative">
        {/* Price List Card */}
        <div className={`bg-white rounded-lg shadow-lg p-6 ${showDetail ? 'hidden md:block' : 'w-full'}`}>
          <h2 className="text-2xl font-semibold mb-4 text-[#377E62]">
            Item Prices
          </h2>

          <div className="mb-4">
            <input
              type="search"
              placeholder="Search by item name, category, group, or unit..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#377E62]"
            />
          </div>

          {error && <ErrorMessage message={error} />}

          {loading ? (
            <LoadingSpinner message="Loading prices..." />
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b">
                    <th className="py-2 px-2 text-left">Item</th>
                    <th className="py-2 px-2 text-left hidden sm:table-cell">Category</th>
                    <th className="py-2 px-2 text-left hidden lg:table-cell">Unit</th>
                    <th className="py-2 px-2 text-right">Avg (RM)</th>
                    <th className="py-2 px-2 text-right hidden sm:table-cell">Range (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map(([itemName, data]) => (
                    <tr
                      key={data.item_code}
                      className="border-b cursor-pointer hover:bg-gray-50 transition-colors"
                      onClick={() => fetchItemPrice(parseInt(data.item_code))}
                    >
                      <td className="py-2 px-2 font-medium">{itemName}</td>
                      <td className="py-2 px-2 hidden sm:table-cell">{data.category}</td>
                      <td className="py-2 px-2 hidden lg:table-cell">{data.unit}</td>
                      <td className="py-2 px-2 text-right">{data.mean.toFixed(2)}</td>
                      <td className="py-2 px-2 text-right hidden sm:table-cell">
                        {data.min.toFixed(2)} - {data.max.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {currentItems.length === 0 && !loading && (
                <div className="text-center py-8 text-gray-500">
                  No items found matching your search criteria
                </div>
              )}
              {currentItems.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                />
              )}
            </div>
          )}
        </div>

        {/* Item Details Card */}
        <div 
          className={`bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 ${
            showDetail 
              ? 'fixed md:relative top-0 right-0 w-full h-full md:h-auto md:w-auto transform translate-x-0 z-50 overflow-y-auto'
              : 'fixed md:relative transform translate-x-full'
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold text-[#377E62]">
              Item Details
            </h2>
            <button 
              onClick={() => setShowDetail(false)}
              className="md:hidden text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>
          
          {itemLoading ? (
            <LoadingSpinner message="Loading item details..." />
          ) : selectedItem ? (
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold mb-2">
                  {selectedItem.item_name}
                </h3>
                <div className="mb-4 text-sm text-gray-600">
                  <p>Category: {selectedItem.category}</p>
                  <p>Group: {selectedItem.group}</p>
                  <p>Unit: {selectedItem.unit}</p>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">Average</p>
                    <p className="font-semibold">RM {selectedItem.average_price}</p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">Min</p>
                    <p className="font-semibold">RM {selectedItem.min_price}</p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-500">Max</p>
                    <p className="font-semibold">RM {selectedItem.max_price}</p>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Price History</h3>
                <div className="bg-gray-50 rounded-lg overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="border-b">
                        <th className="py-2 px-4 text-left">Date</th>
                        <th className="py-2 px-4 text-right">Average (RM)</th>
                        <th className="py-2 px-4 text-right hidden sm:table-cell">Range (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPriceHistory().map(([date, data]) => (
                        <tr key={date} className="border-b">
                          <td className="py-2 px-4">
                            {new Date(date).toLocaleDateString('en-MY', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                          </td>
                          <td className="py-2 px-4 text-right">
                            {data.average_price.toFixed(2)}
                          </td>
                          <td className="py-2 px-4 text-right hidden sm:table-cell">
                            {data.price_range.min.toFixed(2)} - {data.price_range.max.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {totalHistoryPages > 1 && (
                    <Pagination
                      currentPage={historyPage}
                      totalPages={totalHistoryPages}
                      onPageChange={setHistoryPage}
                    />
                  )}
                </div>
              </div>

              {selectedItem.price_history && Object.values(selectedItem.price_history)[0]?.by_state && (
                <div>
                  <h3 className="font-semibold mb-2">Latest Prices by State</h3>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {Object.entries(Object.values(selectedItem.price_history)[0].by_state)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([state, price]) => (
                          <div key={state} className="flex justify-between">
                            <span className="text-gray-600">{state}:</span>
                            <span className="font-medium">RM {price.toFixed(2)}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <p className="text-gray-500">
                Select an item from the list to view detailed price information
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceAnalysis;